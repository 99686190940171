<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <div class="contentList">
      <div class="PERInformation-from">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="子类目">
            <el-select
              style="width: 100px"
              v-model="categories_child"
              clearable
              name="categories_child"
              id="categories_child"
              remote
              @focus.once="GetListPageChild"
            >
              <el-option
                v-for="item in listPageChild"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="机构名称">
            <el-input
              type="text"
              clearable
              v-model="mechanism_name"
              name="mechanism_name"
              id="name"
            />
          </el-form-item>

          <el-form-item label="联系人电话">
            <el-input
              type="text"
              clearable
              v-model="contact_telephone"
              name="mechanism_telephone"
              id="tell"
            />
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="status" style="width: 120px">
              <el-option label="已通过" :value="2"></el-option>
              <el-option label="待审核" :value="1"></el-option>
              <el-option label="审核未通过" :value="3"></el-option>
              <el-option label="注销" :value="4"></el-option>
              <el-option label="已冻结" :value="5"></el-option>
              <el-option label="预留待打电话" :value="6"></el-option>
              <el-option label="预留已联系" :value="7"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="城市">
            <el-cascader
              clearable
              v-model="cityList"
              :options="cityoptions"
              :props="cityProps"
              placeholder="城市"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="mec_type" style="width: 100px">
              <el-option label="运动机构" value="体育运动"></el-option>
              <el-option label="场馆" value="venue"></el-option>
              <el-option label="社区机构" value="community"></el-option>
              <el-option label="艺术机构" value="休闲生活"></el-option>
              <el-option label="夜校" value="夜校"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="运营方">
            <el-select v-model="operate" style="width: 100px">
              <el-option
                v-for="(v, i) in operateList"
                :key="i"
                :label="v.label"
                :value="v.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-button @click="search()" type="primary">查询</el-button>
          </el-form-item>

          <div class="flex">
            <div>
              <el-button @click="openCoachBind()" type="warning"
                >绑定机构教练</el-button
              >
            </div>
            <div>
              <el-button type="primary" @click="openAdd">新增机构</el-button>
            </div>


            <excelExport
              ref="export"
              :tableName="tableName"
              :values="values"
              :titles="titles"
              @startExport="startExport"
            >
            </excelExport>
          </div>
        </el-form>
      </div>
      <div class="paging">
        <el-table
          :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
          :data="recording"
          tooltip-effect="dark"
          style="width: 100%"
          @filter-change="filterChange"
          highlight-current-row
          v-loading="is_loading"
        >
          <!-- <el-table-column prop="id" align="center" label="id"></el-table-column> -->
          <el-table-column
            prop="mechanism_name"
            align="center"
            label="机构名称"
            width="150px"
          >
            <template slot-scope="{ row }">
              <el-popover trigger="hover" placement="top">
                <p>{{ row.mechanism_name }}</p>
                <div slot="reference" class="name-wrapper">
                  <div class="overhide" style="width: 150px">
                    {{ row.mechanism_name }}
                  </div>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="type"
            align="center"
            label="机构类型"
          ></el-table-column> -->
          <el-table-column
            prop="categories_child"
            align="center"
            label="门店类别"
            width="100"
          ></el-table-column>
          <el-table-column align="center" label="运营方">
            <template slot-scope="{ row }">
              <div v-if="row.operate == 'settle'">机构运营</div>
              <div v-if="row.operate == 'haima_self'" style="color: #f78d36">
                官方运营
              </div>
              <span v-if="row.is_community">(社区)</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="mechanism_addr"
            align="center"
            label="机构地址"
          >
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <p>地址: {{ scope.row.mechanism_addr }}</p>
                <div slot="reference" class="name-wrapper">
                  <el-tag size="medium">机构地址</el-tag>
                </div>
              </el-popover>
            </template></el-table-column
          >
          <el-table-column
            prop="contacts"
            align="center"
            label="负责人姓名"
          >
            <template slot-scope="{ row }">
              <p>
                {{ row.parent_id > 0 ? "分店" : row.contacts }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="contact_telephone"
            align="center"
            label="机构电话"
            width="120px"
          >
            <template slot-scope="{ row }">
              <p>
                {{ row.contact_telephone || row.mechanism_telephone || "暂无" }}
              </p>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="contact_telephone"
            align="center"
            label="主账号"
          ></el-table-column> -->
          <el-table-column
            prop="mechanism_name"
            align="center"
            label="关联机构"
            width="150px"
          >
            <template slot-scope="{ row }">
              <el-popover trigger="hover" placement="top">
                <p>{{ row.map.mechanism_name }}</p>
                <div slot="reference" class="name-wrapper">
                  <div class="overhide" style="width: 150px">
                    {{ row.map.mechanism_name }}
                  </div>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            prop="create_time"
            align="center"
            label="申请时间"
            width="160px"
          ></el-table-column>

          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1" style="color: #23c3c4"
                >待审核</span
              >
              <span v-else-if="scope.row.status == 2" style="color: #b8b8b8"
                >已通过</span
              >
              <span v-else-if="scope.row.status == 3" style="color: #ff6262"
                >已拒绝</span
              >
              <span v-else-if="scope.row.status == 4" style="color: #ff6262"
                >注销</span
              >
              <span v-else-if="scope.row.status == 5" style="color: #ff6262"
                >冻结</span
              >
              <span v-else-if="scope.row.status == 6" style="color: #ff6262"
                >待联系</span
              >
              <span v-else-if="scope.row.status == 7" style="color: #b8b8b8"
                >已联系</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="is_recommend"
            align="left"
            label="操作"
            width="250"
          >
            <template slot-scope="scope">
              <div class="flex flex-wrap">
                <el-button
                  @click="toDetails(scope.row)"
                  size="small"
                  type="primary"
                  >查看详情</el-button
                >
                <el-button
                  @click="openUpdate(scope.row)"
                  size="small"
                  type="success"
                  >编辑
                </el-button>
                <el-button
                  @click="showCode(scope.row)"
                  size="small"
                  type="primary"
                  >查看二维码</el-button
                >
                <el-button
                  @click="frozen(scope.row)"
                  size="small"
                  v-if="scope.row.status == 2"
                  type="danger"
                  :loading="scope.row.id == active_id"
                  >冻结
                </el-button>
                <el-button
                  @click="unfrozen(scope.row)"
                  size="small"
                  v-if="scope.row.status == 5"
                  type="warning"
                  >解冻
                </el-button>

                <!-- <el-button
                  @click="handleCurrentChangeto(scope.row)"
                  type="text"
                  size="small"
                  >选择机构</el-button
                > -->
                <!-- <el-button @click="openBindMec(scope.row) " size="small" type="warning">
                  绑定主/分店
                </el-button> -->
                <!-- <el-button
                  @click="openBind(scope.row)"
                  size="small"
                  type="warning"
                >
                  绑定负责人
                </el-button> -->
                <el-button
                  @click="openAccount(scope.row)"
                  size="small"
                  type="warning"
                  v-if="scope.row.operate != 'haima_self'"
                >
                  绑定账户
                </el-button>
                <el-button
                  @click="openHide(scope.row)"
                  size="small"
                  type="success"
                >
                  隐藏展示
                </el-button>
                <!-- <el-button @click="openBonus(scope.row)" size="small" type="success">
                  收益分成
                </el-button> -->
                <!-- <el-button @click="tuijian(scope.row)" size="small"
                  v-if="scope.row.is_recommend != 1 && scope.row.status == 2" type="success">推荐</el-button>
                <el-button @click="tuijian(scope.row)" size="small"
                  v-if="scope.row.is_recommend == 1 && scope.row.status == 2" type="warning">取消推荐</el-button> -->
                <el-button
                  @click="contacted(scope.row)"
                  size="small"
                  v-if="scope.row.status == 6"
                  type="success"
                  >已联系
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      :visible="bindVisible"
      title="绑定用户"
      :before-close="handleClose"
    >
      <label for="">选择用户</label>
      <el-select
        remote
        :remote-method="getUserList"
        v-model="user_id"
        filterable
        placeholder="请输入查询"
      >
        <el-option
          v-for="item in user_list"
          :key="item.user_id"
          :value="item.user_id"
          :label="item.nick_name"
        >
        </el-option>
      </el-select>
      <span slot="footer">
        <el-button @click="bindUser()">确认</el-button>
        <el-button @click="handleClose">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible="bindMecVisible"
      title="绑定主/分店"
      :before-close="handleClose"
    >
      <div>
        <div>
          <label for="主店"></label>
          <div>{{ temp.mechanism_name }}</div>
          <div>
            <label for="分店"></label>
            <div>
              <div v-for="part in part_list" :key="part.id">
                <span>{{ part.binding_mechanism_id }}</span>
                <el-button @click="upgradeToMain(part, part_list)"
                  >提升为主店</el-button
                ><!-- todo -->
                <el-button @click="moveTo(part)">></el-button>
              </div>
            </div>
          </div>
        </div>
        <div v-for="item in main_list" :key="item.id">
          <label for="主店"></label>
          <div>{{ item.mechanism_name }}</div>
          <div>
            <label for="分店"></label>
            <div>
              <div v-for="part in main_list.part_list" :key="part.id">
                <span>{{ part.binding_mechanism_id }}</span>
                <el-button @click="upgradeToMain(part, main_list.part_list)"
                  >提升为主店</el-button
                >
                <el-button>></el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="bindUser()">确认</el-button>
        <el-button @click="handleClose">取消</el-button>
      </span>
    </el-dialog>
    <!-- 教练新增 -->
    <el-dialog title="绑定教练" :visible.sync="CheckDialogVisible" width="30%">
      <div>
        <el-form :inline="true">
          <el-form-item label="绑定机构" required>
            <el-select
              v-model="select_mechanism"
              collapse-tags
              multiple
              placeholder="请选择"
              filterable
              remote
              :remote-method="searchMec"
            >
              <el-option
                v-for="item in all_mechanism"
                :key="item.id"
                :label="item.mechanism_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-checkbox v-model="checked" @change="selectAll"
              >全选</el-checkbox
            >
          </el-form-item>
          <el-form-item label="教练电话" required>
            <el-input
              v-model="coach_phone"
              placeholder="请输入教练电话"
              required
            ></el-input>
          </el-form-item>
        </el-form>
        <div v-if="tips">
          <p>该电话已注册成为教练，你可以邀请教练入驻机构</p>
          <el-button @click="send">发送邀请</el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="CheckDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handlePhone">确 定</el-button>
      </span>
    </el-dialog>
    <!--  -->
    <el-dialog
      :visible="codedialogVisible"
      title="机构二维码"
      :before-close="handleClose"
    >
      <div class="flex al jc-c">
        <vue-qr ref="Qrcode" :text="dataObj.text" qid="testQrId"></vue-qr>
      </div>
      <div class="flex al jc-c">
        <a
          class="load"
          :href="exportLink"
          @click="downloadImg"
          :download="downloadFilename"
          >下载二维码</a
        >
      </div>
      <span slot="footer">
        <el-button @click="codedialogVisible = false" type="primary"
          >确认</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="隐藏设置" :visible.sync="hideShow" width="30%">
      机构信息是否在'海马运动'端展示，隐藏后将无法使在列表，搜索页查看到机构信息。
      <div>
        <el-radio v-model="temp.hide" :label="false">展示</el-radio>
        <el-radio v-model="temp.hide" :label="true">隐藏</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideShow = false">取 消</el-button>
        <el-button type="primary" @click="handlerHide">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="提现账户"
      :visible.sync="accountShow"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form ref="form" label-width="100px">
        <el-form-item label="账户姓名">
          <el-input v-model="aliform.name"></el-input>
        </el-form-item>
        <el-form-item label="支付宝账号">
          <el-input v-model="aliform.aliPay"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="accountShow = false">取 消</el-button>
        <el-button type="primary" @click="savePayAccount">确 定</el-button>
      </span>
    </el-dialog>

    <addCoach
      ref="add"
      :phone="coach_phone"
      :select_mechanism="select_mechanism"
    ></addCoach>
    <!--分页-->
    <paging-fy
      @currentPageChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    ></paging-fy>
    <addBonusVue ref="bonus" :mecid="curId" />
    <editMechanism ref="edit" @success="search" />
  </div>
</template>
<script>
import addCoach from "./components/addCoach.vue";
import VueQr from "vue-qr";
import excelExport from "../../components/excel/excelExport.vue";
import addBonusVue from "../../components/bonus/addBonus.vue";
import editMechanism from "./components/mechanism/editMechanism.vue";
export default {
  components: {
    VueQr,
    addCoach,
    excelExport,
    addBonusVue,
    editMechanism,
  },
  data() {
    return {
      activeName: 0,
      tabslist: ["未入驻", "已入驻"],
      nav: {
        firstNav: "机构管理中心",
        secondNav: "机构管理",
      },
      CheckDialogVisible: false,
      status: 2,
      disableds: true,
      bindMecVisible: false,
      leimuF: "",
      leimuZ: "",
      is_loading: false,
      type: "teach_paypal",
      region: [],
      listPic: [],
      listPageChild: [],
      recording: [], //精品课程
      pageSize: 10,
      currentPage: 1,
      total: 0,
      codedialogVisible: false,
      dialogVisible: false,
      updateVisible: false,
      mechanism_name: "",
      mechanism_telephone: "",
      curId: "",
      form: {},
      temp: {},
      contact_telephone: null,
      categories_child: null,
      bindVisible: false,
      user_list: [],
      user_id: null,
      main_list: [],
      part_list: [],
      codeUrl: "",
      active_id: -1,
      dataObj: {},
      downloadFilename: "",
      exportLink: "",
      ks1: "",
      all_mechanism: [],
      select_mechanism: [],
      coach_phone: "",
      tips: false,
      checked: false,
      is_community: "",
      hideShow: false,
      mec_type: "",
      // 表格
      DataList: [],
      tableName: "海马运动机构",
      values: [
        "mechanism_name",
        "categories_child",
        "contacts",
        "contact_telephone",
        "mechanism_telephone",
        "mechanism_addr",
      ],
      titles: ["机构名称", "类目", "联系人", "电话", "机构电话", "地址"],
      cityoptions: [],
      cityProps: {
        checkStrictly: true,
        value: "district",
        label: "district",
        children: "childrens",
      },
      cityList: [],
      accountShow: false,
      aliform: { name: "", aliPay: "" },

      operate: "",
      operateList: [
        {
          label: "机构运营",
          value: "settle",
        },
        {
          label: "官方运营",
          value: "haima_self",
        },
      ],
    };
  },
  updated() {
    this.ks1 = window.screen.width;
  },
  created() {
    this.GetList();
    this.GetListPageChild();
    this.getAddrList();
  },
  methods: {
    openAdd() {
      this.$refs.edit.open();
    },
    getAddrList() {
      let list = JSON.parse(localStorage.getItem("addrList"));
      let area = JSON.parse(localStorage.getItem("cur_agentAreas"));
      let cooperator = JSON.parse(localStorage.getItem("cooperator"));
      if (cooperator && area) {
        let province;
        for (let i = 0; i < list.length; i++) {
          if (list[i].district === area.province) {
            province = list[i];
            break;
          }
        }

        console.log(province);
        if (cooperator.agent_level == "province") {
          this.cityoptions = province.childrens;
        }
        if (cooperator.agent_level == "city") {
          province.childrens.forEach((item) => {
            if (item.district == area.city) {
              this.cityoptions = item.childrens;
            }
          });
        }
      } else {
        this.cityoptions = list;
      }
    },
    openBonus(row) {
      this.curId = row.id;
      this.$refs.bonus.open();
    },
    // 导出表格
    startExport() {
      this.exportInfo();
    },
    exportInfo() {
      let params = {
        operate: this.operate || null,
        type: this.type,
        status: this.status !== null ? this.status : null,
        pageSize: 10,
        currentPage: this.currentPage,
        mechanism_name: this.mechanism_name ? this.mechanism_name : null,
        contact_telephone: this.contact_telephone
          ? this.contact_telephone
          : null,
        contacts: this.contacts ? this.contacts : null,
        categories_child: this.categories_child ? this.categories_child : null,
      };
      if (this.mec_type == "community") {
        params.is_community = true;
      } else {
        params.categories = this.mec_type || null;
      }
      let cooperator = JSON.parse(localStorage.getItem("cooperator"));
      if (this.cityList.length > 0) {
        let len = this.cityList.length;
        if (cooperator) {
          switch (cooperator.agent_level) {
            case "province":
              params.city = this.cityList[len - 1];
              break;
            case "city":
              params.district = this.cityList[len - 1];
              break;
            default:
              break;
          }
        } else {
          let list = ["province", "city", "district"];
          params[list[len - 1]] = this.cityList[len - 1];
        }
      }
      this.$axios({
        url: "/user/mastermechanism/query",
        params,
      }).then((res) => {
        this.DataList = res.data.data;
        this.$refs.export.importExcel(this.DataList);
      });
    },
    openCoachBind() {
      this.CheckDialogVisible = true;
    },
    searchMec(query) {
      const numberReg = /^\d/;
      if (query && typeof query == "string") {
        let data = {
          status: 2,
          pageSize: 30,
          currentPage: 1,
        };
        numberReg.test(query)
          ? (data.mechanism_telephone = query)
          : (data.mechanism_name = query);
        let url = "/user/mastermechanism/queryByMessage";
        this.$axios
          .get(url, {
            params: data,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.all_mechanism = res.data.data.rows;
            }
          });
      }
    },
    selectAll(v) {
      if (v) {
        this.select_mechanism = this.all_mechanism.map((item) => {
          return item.id;
        });
      } else {
        this.select_mechanism = [];
      }
    },
    send() {
      let url = "/user/pushHistory/insert";
      this.$axios
        .post(url, {
          large_type: "mechanism_coach",
          send_id: this.select_mechanism[0],
          target_id: this.coachList.id,
          context: this.select_mechanism.join(","),
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.CheckDialogVisible = false;
            this.$confirm("已向教练发送入驻邀请");
          }
        });
    },
    handlePhone() {
      if (this.select_mechanism.length <= 0) {
        this.$message("请选择要绑定的机构");
        return;
      }
      if (this.coach_phone == "") {
        this.$message("请输入电话");
        return;
      }
      let reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.coach_phone)) {
        this.$message("请输入正确的手机号");
        return;
      }

      let url = "/user/coach/getByPhone";
      this.$axios
        .get(url, {
          params: {
            phone: this.coach_phone,
          },
        })
        .then((res) => {
          if (res.data.code == 0 && res.data.data) {
            this.tips = true;
            this.coachList = res.data.data;
          } else {
            this.CheckDialogVisible = false;
            this.$refs.add.display(true);
          }
        });
    },

    downloadImg() {
      let Qrcode = this.$refs.Qrcode;
      this.exportLink = Qrcode.$el.currentSrc;
      this.downloadFilename = "二维码";
    },
    showCode(row) {
      let that = this;
      this.codedialogVisible = true;
      let type = row.type == "商户" ? "商户" : "机构";
      let info = `id:${row.id},type:${type}`;
      this.dataObj = {
        text: info,
      };
    },
    handleClick() {},
    handlerHide() {
      let url = "user/mastermechanism/update";
      let data = {
        id: this.temp.id,
        hide: this.temp.hide,
      };
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        this.hideShow = false;
        this.GetList();
      });
    },
    addVisible(event) {
      if (event == false) {
        if (this.leimuF != "") {
          this.disableds = false;
        } else {
          this.disableds = true;
          this.leimuZ = "";
        }
      }
    },
    openBind(row) {
      this.temp = row;
      this.bindVisible = true;
    },
    savePayAccount() {
      if (this.aliform.id) {
        this.$axios({
          url: "/user/rechargeInfo/update",
          method: "post",
          data: {
            id: this.aliform.id,
            name: this.aliform.name,
            aliPay: this.aliform.aliPay,
          },
        }).then((res) => {
          this.$message("绑定成功");
          this.accountShow = false;
        });
      } else {
        this.$axios({
          url: "/user/rechargeInfo/insert",
          method: "post",
          data: {
            mechanism_id: this.temp.parent_id || this.temp.id,
            name: this.aliform.name,
            aliPay: this.aliform.aliPay,
          },
        }).then((res) => {
          this.$message("绑定成功");
          this.accountShow = false;
        });
      }
    },
    openAccount(row) {
      this.temp = row;

      this.$axios({
        url: "/user/rechargeInfo/queryListPage",
        params: {
          mechanism_id: row.parent_id || row.id,
        },
      }).then((res) => {
        this.aliform = res.data.data[0] || {};
        this.accountShow = true;
      });
    },
    openHide(row) {
      this.temp = { ...row };
      this.hideShow = true;
    },
    bindUser() {
      let url = "/user/mastermechanism/updateMechanism";
      this.$axios
        .post(url, {
          user_id: this.user_id,
          id: this.temp.id,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
          } else {
            this.$message({
              type: "error",
              message: re.data.message,
            });
          }
        });
    },
    // 类目查询
    getUserList(query) {
      let url = "/user/userInfo/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: 1,
            nick_name: typeof query == "string" ? query : undefined,
          },
        })
        .then((res) => {
          this.user_list = res.data.data.rows;
        });
    },
    upgradeToMain(part) {
      let a = this.part_list.findIndex((item) => {
        return item.id == part.id;
      });
      this.main_list.push(part);
      this.part_list.splice(a, 1);
    },
    openBindMec(row) {
      let url = "/user/mastermechanismSchool/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            statsu: 2,
            mechanism_id: row.map.mechanism_name ? null : row.id,
            binding_mechanism_id: row.map.mechanism_name ? row.id : null,
          },
        })
        .then((res) => {
          if (row.map.mechanism_name) {
            this.main_list = [];
            this.temp = row;
            this.part_list = res.data.data.rows;
            this.bindMecVisible = true;
          } else {
            this.bindMainVisible = true;
          }
        });
    },
    getPartList(query) {
      let url = "/user/mastermechanismSchool/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            statsu: 2,
            mechanism_id: temp.map.mechanism_name ? null : row.id,
            bind_mechanism_id: typeof query == "string" ? query : null,
          },
        })
        .then((res) => {
          if (res.data.data.rows.length) {
            this.part_list = [];
            this.part_list = res.data.data.rows.filter((item) => {
              this.main_list.forEach((mian) => {
                return item.id != mian.id;
              });
            });
            // this.bindMecVisible = true
            // console.log(this.main_list, this.part_list, 222)
          } else {
            // this.bindMainVisible = true
          }
        });
    },
    GetListPageChild() {
      let url = this.$axios({
        url: "/user/mechanismCategory/queryListPageChild",
        params: {
          type: 2,
          status: 2,
          source: "课程",
        },
      }).then((res) => {
        this.listPageChild = res.data.data;
      });
    },

  
    search() {
      this.currentPage = 1;
      this.GetList();
    }, //查询
    tuijian(e) {
      // console.log(e, "==========我是e========");
      let url = "/user/mastermechanism/update";
      let data = {
        id: e.id,
        is_recommend: e.is_recommend ? 1 : 0,
      };
      // console.log(e.is_recommend);
      if (data.is_recommend) {
        data.is_recommend = false;
      } else {
        data.is_recommend = true;
      }
      this.$axios.post(url, data).then((res) => {
        // console.log(res, "===========推荐========");
        this.GetList();
      });
    }, // 推荐
    filterChange(filterObj) {
      // console.log(filterObj);
    },
    GetList() {
      let params = {
        operate: this.operate || null,
        status: this.status !== null ? this.status : null,
        is_community: this.is_community || null,
        pageSize: 10,
        currentPage: this.currentPage,
        mechanism_name: this.mechanism_name ? this.mechanism_name : null,
        contact_telephone: this.contact_telephone
          ? this.contact_telephone
          : null,
        contacts: this.contacts ? this.contacts : null,
        categories_child: this.categories_child ? this.categories_child : null,
      };

      if (this.mec_type == "community") {
        params.is_community = true;
        params.type = this.type
      }else if(this.mec_type == "venue"){
        params.type = 'venue'
      } else {
        params.categories = this.mec_type || null;
        // params.type = this.type
      }

      let cooperator = JSON.parse(localStorage.getItem("cooperator"));
      if (this.cityList.length > 0) {
        let len = this.cityList.length;
        if (cooperator) {
          switch (cooperator.agent_level) {
            case "province":
              params.city = this.cityList[len - 1];
              break;
            case "city":
              params.district = this.cityList[len - 1];
              break;
            default:
              break;
          }
        } else {
          let list = ["province", "city", "district"];
          params[list[len - 1]] = this.cityList[len - 1];
        }
      }

      this.is_loading = true;
      let url = "/user/mastermechanism/queryByMessage";
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          // console.log(res.data);
          this.recording = res.data.data.rows;
          this.total = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    openUpdate(row) {
      this.$refs.edit.open(row);
    },
    toDetails(row) {
      this.$router.push({ name: "mecDetails", query: { mecID: row.id } });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.GetList();
    },
    frozen(row) {
      let url = "/user/mastermechanism/updateFreeze";
      this.$axios({
        url,
        method: "post",
        data: {
          id: row.id,
        },
      })
        .then((res) => {
          this.active_id = -1;
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "冻结成功!" });
          } else {
            this.$message({ type: "danger", message: res.data.message });
          }
          this.GetList();
        })
        .catch(() => {
          // this.$message({ type: "danger", message: "请求失败" });
          this.active_id = -1;
          this.GetList();
        });
    },
    unfrozen(row) {
      console.log("2");
      let url = "/user/mastermechanism/updateUnFreeze";
      this.$axios({
        url,
        method: "post",
        data: { id: row.id },
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "success", message: "解冻成功!" });
        } else {
          this.$message({ type: "danger", message: res.data.message });
        }
        this.GetList();
      });
    },
    contacted(row) {
      let url = "/user/mastermechanism/update";
      this.$axios
        .post(url, {
          id: row.id,
          status: 7,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "修改成功!" });
          } else {
            this.$message({ type: "danger", message: res.data.message });
          }
          this.GetList();
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭?")
        .then((_) => {
          this.user_id = null;
          this.bindVisible = false;
          this.bindMecVisible = false;
          this.codedialogVisible = false;
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>
<style lang="less">
.PERInformation-from > *:not(:last-child) {
  margin-right: 1rem;
}

.el-button {
  margin-bottom: 5px;
}

.load {
  margin-top: 20px;
  color: rgb(96, 186, 238) !important;
}

.overhide {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
